import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Icon, LinkBox, Box, Link, Section } from "@quarkly/widgets";
import { TiArrowRight } from "react-icons/ti";
const defaultProps = {
	"padding": "120px 0 120px 0",
	"md-padding": "80px 0 90px 0",
	"quarkly-title": "Headline-3",
	"background": "linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(0, 0, 0, 0.35) 0%,rgba(0, 0, 0, 0.87) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6628cf0e8d4a0c00202eb032/images/4-2.jpg?v=2024-04-24T10:36:15.797Z) 0% 0% /cover repeat scroll padding-box"
};
const overrides = {
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 700 52px/1.2 --fontFamily-sans",
			"color": "--light",
			"sm-font": "normal 700 42px/1.2 --fontFamily-sans",
			"children": <>
				Будуємо ваш дім мрії{" "}
			</>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"lg-margin": "0px 20% 50px 0px",
			"md-margin": "0px 0 50px 0px",
			"color": "--light",
			"font": "--headline3",
			"margin": "0px 50% 3rem 0px",
			"children": "Будівельна компанія з досвідом і високою якістю."
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"grid-template-columns": "repeat(4, 1fr)",
			"grid-gap": "16px 14px",
			"margin": "0px 0 60px 0px",
			"align-self": "flex-start",
			"lg-flex-wrap": "wrap",
			"display": "none"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"justify-content": "flex-start",
			"href": "/"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 5px 0px 0px",
			"color": "--light",
			"font": "normal 500 20px/1.2 --fontFamily-sans",
			"children": "Wykrywanie i naprawa nieszczelności"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "ti",
			"icon": TiArrowRight,
			"size": "26px",
			"color": "--light",
			"margin": "0px 15px 0px 0px"
		}
	},
	"linkBox1": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"justify-content": "flex-start",
			"href": "/"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 5px 0px 0px",
			"color": "--light",
			"font": "normal 500 20px/1.2 --fontFamily-sans",
			"children": "Naprawa przebić"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "ti",
			"icon": TiArrowRight,
			"size": "26px",
			"color": "--light",
			"margin": "0px 15px 0px 0px"
		}
	},
	"linkBox2": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"justify-content": "flex-start",
			"href": "/"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 5px 0px 0px",
			"color": "--light",
			"font": "normal 500 20px/1.2 --fontFamily-sans",
			"children": "Kontrola i regulacja ciśnienia"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "ti",
			"icon": TiArrowRight,
			"size": "26px",
			"color": "--light",
			"margin": "0px 15px 0px 0px"
		}
	},
	"linkBox3": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"justify-content": "flex-start",
			"href": "/"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 5px 0px 0px",
			"color": "--light",
			"font": "normal 500 20px/1.2 --fontFamily-sans",
			"children": "Centrowanie koła"
		}
	},
	"icon3": {
		"kind": "Icon",
		"props": {
			"category": "ti",
			"icon": TiArrowRight,
			"size": "26px",
			"color": "--light"
		}
	},
	"linkBox4": {
		"kind": "LinkBox",
		"props": {
			"flex-direction": "row",
			"justify-content": "flex-start",
			"href": "/"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 5px 0px 0px",
			"color": "--light",
			"font": "normal 500 20px/1.2 --fontFamily-sans",
			"children": "Sezonowy serwis opon"
		}
	},
	"icon4": {
		"kind": "Icon",
		"props": {
			"category": "ti",
			"icon": TiArrowRight,
			"size": "26px",
			"color": "--light"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "#",
			"text-decoration-line": "initial",
			"color": "--light",
			"font": "--lead",
			"hover-opacity": "0.9",
			"border-radius": "8px",
			"padding": "11px 3rem 12px 3rem",
			"text-align": "center",
			"align-self": "auto",
			"background": "rgba(0, 57, 255, 0.69)",
			"children": "Наші послуги"
		}
	}
};

const Hero = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" sm-min-width="280px" />
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Box {...override("box")}>
			<LinkBox {...override("linkBox")}>
				<Text {...override("text2")} />
				<Icon {...override("icon")} />
			</LinkBox>
			<LinkBox {...override("linkBox1")}>
				<Text {...override("text3")} />
				<Icon {...override("icon1")} />
			</LinkBox>
			<LinkBox {...override("linkBox2")}>
				<Text {...override("text4")} />
				<Icon {...override("icon2")} />
			</LinkBox>
			<LinkBox {...override("linkBox3")}>
				<Text {...override("text5")} />
				<Icon {...override("icon3")} />
			</LinkBox>
			<LinkBox {...override("linkBox4")}>
				<Text {...override("text6")} />
				<Icon {...override("icon4")} />
			</LinkBox>
		</Box>
		<Link {...override("link")} />
		{children}
	</Section>;
};

Object.assign(Hero, { ...Section,
	defaultProps,
	overrides
});
export default Hero;